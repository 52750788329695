import React from 'react';
import { Helmet } from 'react-helmet';
import HeroBackground from '../components/HeroBackground/HeroBackground';
import Layout from '../components/Layout/layout';
import styles from '../styles/index.module.css';
import '../styles/minireset.css';

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>River City Drafthouse</title>
      <meta
        name='River City Drafthouse'
        content='River City Drafthouse serves a wide variety of beer and Asian and American dishes'
      />
    </Helmet>
    <HeroBackground className={styles.gatsbyImageWrapper}>
      <div className={styles.hero}>
        <h1 className={styles.heroText}>
          Welcome to River City Draft House
        </h1>
        <p className={styles.heroSubtitle}>A friendly, family-owned neighborhood pub</p>
      </div>
    </HeroBackground>
  </Layout>
);
